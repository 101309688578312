<template>
  <div class='add-your-bank-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <div>
            <div class='add-your-bank-title'>Add Your Bank</div>
            <div class='add-your-bank-stepper'>Step 2</div>
              <div class='button-with-arrow-wrapper' @click="addBankManually">
              <div style='text-align: initial'>
                <div class="btn-arrow-text">Add Bank Manually</div>
                <div class="btn-arrow-sub-text">Select your bank and add account number</div>
              </div>
              <div><arrow-right-outlined style='font-size: 24px' /></div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';

export default {
  components: {
    ArrowRightOutlined,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
        });
    });
    const router = useRouter();
    const addBankManually = () => {
      router.push('/onboarding/bank/details');
      store.dispatch('onboardingStore/updateCurrentStage', STAGES.STAGE_TYPE.BANK_DETAILS);
    };

    return {
      addBankManually,
      value1: ref(),
      checked: ref(false),
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/addYourBank.scss';
</style>
